import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useViewQuote as useViewQuoteGraphQL } from '../../api/graphql/viewCompareQuote';
import { useViewComparedQuote as useViewQuoteREST } from '../../api/rest/useViewComparedQuote';
import type { UseViewQuoteResult } from 'types/quote';

export const useComparedQuoteView = ({ skip }: { skip: boolean }): UseViewQuoteResult => {
  const {
    flags: { tireQuoteGql },
    isLoaded,
  } = useLaunchDarkly();

  const quoteGraphQL = useViewQuoteGraphQL({ skip: skip || (!tireQuoteGql && isLoaded) });
  const quoteREST = useViewQuoteREST({ skip: skip || Boolean(tireQuoteGql) || !isLoaded });

  if (tireQuoteGql) {
    return quoteGraphQL;
  }

  return quoteREST;
};
