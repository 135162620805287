import { Box, Divider, useMedia } from '@partstech/ui';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { cartBarWidth } from 'components/Layout/Layout.styles';
import { Routes } from 'constant';
import { RecentSearchesDropdown } from 'features/recentSearches';
import { isSearchEntryPoint, SearchForm, SearchInputSize, SearchTypeSelector } from 'features/searchForm';
import { VehicleSelectorDropdown } from 'features/vehicleSelector';
import { useSearchParams } from 'hooks/search';
import { useSearchSequenceStorage } from 'hooks/searchSequence';
import { useOpenAddSupplierModal, useSupplierAccounts } from 'hooks/supplierAccounts';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { SearchTypes } from 'types/search';
import { isTiresSearchEntry } from 'utils';
import { RefineSearchButton } from './components/RefineSearchButton';
import { SupplierSelector } from './components/SupplierSelector';
import { useSearchBar } from './context/SearchBarContext';
import { useSearchBarStateValidation } from './hooks/useSearchBarStateValidation';
import type { RecentSearchInterface } from 'features/recentSearches';
import type { SearchFormRef } from 'features/searchForm';
import type { Vehicle } from 'models';

const getPlaceholderText = (searchType: SearchTypes) =>
  searchType === SearchTypes.PARTS
    ? 'Search by job, product, category, brand or part #'
    : 'Search by part # or tire size (ex: 2256517 or 225/65R17)';

export const SearchBar = memo(() => {
  const { isMobile, isTablet, isDesktop } = useMedia();

  const {
    shop: { jobsAllowed },
  } = usePermissions();

  const isSearchResultsPage = Boolean(useMatch(Routes.SEARCH_RESULTS));
  const isDashboard = Boolean(useMatch(Routes.DASHBOARD));

  const searchForm = useRef<SearchFormRef | null>(null);

  const {
    flags: { clearSearchBarAfterChangingVehicle, fluidsChemicalsTab, jobsVisibility },
  } = useLaunchDarkly();

  const { hasAccounts: hasTiresAccounts } = useSupplierAccounts({ type: 'tires' });
  const { open: openAddSupplierModal } = useOpenAddSupplierModal();

  const {
    searchParams: { plate, state, vin, year, make, model, submodel, vehicle: vehicleIdFromUrl },
  } = useSearchParams();

  const {
    changeEntryPointsTab,
    changeSearchEntry,
    changeSearchType,
    changeVehicle,
    openVehicleSelector,
    entryPointsTab,
    searchEntry,
    searchFormType: searchType,
    isSelectVehicleOpen,
    closeVehicleSelector,
    vehicle,
  } = useSearchBar();

  const { reset: resetSearchSequence } = useSearchSequenceStorage();

  const [wasCleared, setWasCleared] = useState(false);

  const shouldShowAdditionalControls = isMobile && isSearchResultsPage;

  const openSearchDropdown = useCallback(() => {
    searchForm?.current?.focus();
  }, []);

  const closeSearchDropdown = useCallback(() => {
    searchForm?.current?.close();
  }, []);

  useSearchBarStateValidation({ onSearchEntryRequired: openSearchDropdown, onVehicleRequired: openVehicleSelector });

  const handleSearchFormClear = useCallback(() => {
    changeSearchEntry(null);
  }, [changeSearchEntry]);

  const handleSearchTypeChange = useCallback(
    (type: SearchTypes) => {
      if (type === SearchTypes.TIRES && !hasTiresAccounts) {
        openAddSupplierModal('tires');
        return;
      }

      changeSearchType(type);
      changeSearchEntry(null);
      searchForm?.current?.clear();
    },
    [changeSearchEntry, changeSearchType, hasTiresAccounts, openAddSupplierModal]
  );

  const handleVehicleChange = useCallback(
    (newVehicle: Vehicle | null) => {
      const isCleared = newVehicle === null;

      const isFirstTimeSet = vehicle === null && !vehicleIdFromUrl && newVehicle !== null;
      const skipChangeEvent = isCleared || !isFirstTimeSet;

      setWasCleared(isCleared);
      changeVehicle(newVehicle, skipChangeEvent);

      if (isCleared || !isFirstTimeSet) {
        resetSearchSequence();
      }

      const searchInput = searchForm.current?.input ?? '';

      if (newVehicle) {
        if ((searchEntry && clearSearchBarAfterChangingVehicle && !isFirstTimeSet) || isTiresSearchEntry(searchEntry)) {
          searchForm?.current?.focus();
          searchForm?.current?.clear();
        }

        if (!searchEntry && searchInput.length === 0) {
          searchForm?.current?.focus();
        }

        if (!searchEntry && searchInput) {
          searchForm.current?.submit();
        }
      }
    },
    [changeVehicle, resetSearchSequence, searchEntry, clearSearchBarAfterChangingVehicle, vehicle, vehicleIdFromUrl]
  );

  const handleRecentSearchSelect = useCallback(
    (recentSearch: RecentSearchInterface) => {
      if (!recentSearch.vehicle?.id) {
        changeVehicle(null, true);
      }

      if (isSearchEntryPoint(recentSearch)) {
        changeSearchEntry(recentSearch);
      }
    },
    [changeSearchEntry, changeVehicle]
  );

  const searchInputSize = isMobile || isTablet ? SearchInputSize.DENSE : SearchInputSize.DEFAULT;

  const vehicleValue = useMemo(() => {
    if (vehicle) {
      return vehicle;
    }

    if (year) {
      return { year, makeId: make, modelId: model, submodelId: submodel };
    }

    if (wasCleared) {
      return null;
    }

    if (plate && state) {
      return { plate, state };
    }

    if (vin) {
      return { vin };
    }

    return null;
  }, [make, model, plate, state, submodel, vehicle, vin, wasCleared, year]);

  return (
    <Box
      display="flex"
      flex="1 1 auto"
      justifyContent="center"
      width="100%"
      flexDirection={!isMobile ? 'row' : 'column'}
      flexWrap={isMobile ? 'wrap' : 'nowrap'}
      data-testid="searchBar"
    >
      <Box width="100%" mr={!isMobile ? 4 : undefined} alignSelf={isDesktop || isTablet ? 'center' : undefined}>
        <SearchForm
          adornmentBefore={
            <>
              <SearchTypeSelector value={searchType} onChange={handleSearchTypeChange} onOpen={closeSearchDropdown} />
              {searchInputSize === SearchInputSize.DEFAULT && <Divider transform="rotate(90deg)" width={6} ml={2} />}
            </>
          }
          adornmentAfter={
            <>
              {shouldShowAdditionalControls && <RefineSearchButton onStartNewSearch={openVehicleSelector} />}
              {!isMobile && <RecentSearchesDropdown onSelect={handleRecentSearchSelect} onOpen={closeSearchDropdown} />}
            </>
          }
          onChange={changeSearchEntry}
          onClear={handleSearchFormClear}
          onSelectVehicleClick={openVehicleSelector}
          onTypeChange={handleSearchTypeChange}
          type={searchType}
          value={searchEntry}
          vehicle={vehicle}
          withSearchEntryPoints={!isDashboard}
          withDynamicAttributes={fluidsChemicalsTab}
          size={searchInputSize}
          tab={entryPointsTab}
          onTabChange={changeEntryPointsTab}
          ref={searchForm}
          shouldShowCancelButton={!isDashboard}
          placeholder={getPlaceholderText(searchType)}
          offset={isMobile ? 0 : 6}
          usePortal={!isMobile}
          withJobsPriority={jobsVisibility && jobsAllowed}
        />
      </Box>

      <Box
        alignSelf="start"
        width={{ sm: '100%', md: '200px', xl: `${cartBarWidth - 15}px` }}
        mt={{ sm: 4, md: 0 }}
        flexShrink={0}
        alignItems="center"
        mb={{ sm: 1, md: 0 }}
      >
        <VehicleSelectorDropdown
          isOpened={isSelectVehicleOpen}
          onOpen={openVehicleSelector}
          onClose={closeVehicleSelector}
          onChange={handleVehicleChange}
          value={vehicleValue}
        />
      </Box>

      {shouldShowAdditionalControls && (
        <Box width="100%" mt={2} mb={-2}>
          <SupplierSelector />
        </Box>
      )}
    </Box>
  );
});
