import { Box, useMedia } from '@partstech/ui';
import React, { useCallback } from 'react';
import {
  useRemoveBackorderFilter,
  useAddDefaultAvailabilityFilter,
  useChangeFilter,
  useSearchParams,
} from 'hooks/search';
import { ConfigureRetailPrice } from 'pages/SearchResults/ConfigureRetailPrice';
import { DiagramQuickLink } from '../DiagramQuickLink';
import { EmptyFilters } from './EmptyFilters';
import { Filter } from './Filter';
import { SelectedFilters } from './SelectedFilters';
import type { Filter as FilterType } from 'types/search';

type Props = {
  filters: FilterType[];
  selectedFiltersChip?: boolean;
  onSearch?: (e: React.MouseEvent) => void;
  onRedirect?: () => void;
};

export const Filters = ({ filters, selectedFiltersChip = false, onSearch = () => {}, onRedirect }: Props) => {
  const { isMobile } = useMedia();
  const { isTiresSearch } = useSearchParams();

  const { changeFilter, checkedFilters, clearAll } = useChangeFilter(filters);

  useRemoveBackorderFilter();
  useAddDefaultAvailabilityFilter();

  const handleOptionCheckboxChange = useCallback(
    (filter: FilterType) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = e.target;

      changeFilter({ name: filter.name, value, checked, cascade: filter.cascade });
    },
    [changeFilter]
  );

  const handleSelectedFilterRemove = useCallback(
    (name: FilterType['name'], value: string) => {
      const cascade = filters.find((filter) => filter.name === name)?.cascade;

      changeFilter({ name, value, cascade, checked: false });
    },
    [changeFilter, filters]
  );

  if (filters.length === 0) {
    return isMobile ? <EmptyFilters onStartNewSearchClick={onSearch} /> : null;
  }

  return (
    <Box width={{ sm: '100%', md: 'auto' }} background={{ sm: 'white', md: 'inherit' }} data-testid="filtersBlock">
      {isMobile && <ConfigureRetailPrice onRedirect={onRedirect} />}
      {isMobile && !isTiresSearch && <DiagramQuickLink />}

      {selectedFiltersChip && checkedFilters.length > 0 && (
        <SelectedFilters
          values={checkedFilters}
          onClear={clearAll}
          onValueRemove={handleSelectedFilterRemove}
          data-testid="selectedFilters"
          mb={4}
        />
      )}

      {filters.map((filter) => (
        <Filter key={filter.name} onChange={handleOptionCheckboxChange(filter)} {...filter} />
      ))}
    </Box>
  );
};
