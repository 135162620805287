import { entries } from '@partstech/ui/utils';
import { useCallback, useMemo, useState } from 'react';
import { useDependentAttribute } from '../../api/useDependentAttribute';
import type { UniversalPartType, Vehicle } from 'models';

const popularAttributes: Record<string, { label: string; values: string[] }> = {
  Viscosity: {
    label: 'Viscosities',
    values: ['5W-30', '5W-20', '10W-30', '10W-40', '0W-20'],
  },
};

type Attributes = Record<string, string[]>;

type Props = {
  partType: UniversalPartType;
  onReset?: () => void;
  vehicle: Vehicle | null;
};

export const useDynamicAttributeConfigurator = ({ partType, onReset, vehicle }: Props) => {
  const [selectedAttributes, setSelectedAttributes] = useState<Attributes>(
    partType.attributes?.reduce<Attributes>((acc, attr) => ({ ...acc, [attr.name]: [] }), {}) ?? {}
  );

  const activeAttribute = useMemo(
    () => partType.attributes?.find((attr) => selectedAttributes[attr.name]?.length === 0),
    [partType.attributes, selectedAttributes]
  );

  const isAttributeSelected = useCallback(
    (attributeName: string) => (selectedAttributes[attributeName]?.length ?? 0) > 0,
    [selectedAttributes]
  );

  const isFirstAttribute = useMemo(
    () => !partType.attributes?.some(({ name }) => isAttributeSelected(name)),
    [isAttributeSelected, partType.attributes]
  );

  const isLastAttribute = useMemo(
    () => partType.attributes?.filter(({ name }) => !isAttributeSelected(name)).length === 1,
    [isAttributeSelected, partType.attributes]
  );

  const previousAttributes = useMemo(
    () =>
      entries(selectedAttributes)
        .map(([name, values]) => ({ name, value: values[0] ?? '' }))
        .filter((selectedAttribute) => Boolean(selectedAttribute.value)),
    [selectedAttributes]
  );

  const { dependentAttribute: activeDependentAttribute, isFetching } = useDependentAttribute({
    activeAttributeName: activeAttribute?.name ?? '',
    partType,
    previousAttributes,
    skip: !partType.hasMultipleAttributes() || Boolean(!vehicle && isFirstAttribute),
    vehicleId: vehicle?.id ?? null,
  });

  const getSelectedValue = useCallback(
    (attributeName: string) => selectedAttributes[attributeName] ?? [],
    [selectedAttributes]
  );

  const getBreadcrumbTitle = useCallback(
    (attributeName: string) =>
      isAttributeSelected(attributeName)
        ? `${getSelectedValue(attributeName)} selected`
        : `Select a ${attributeName.toLowerCase()}`,
    [getSelectedValue, isAttributeSelected]
  );

  const clearSelectedAttributes = useCallback(
    (attributeName: string) => {
      setSelectedAttributes((prevAttributes) => {
        const attributeIndex = partType.attributes?.findIndex((attr) => attr.name === attributeName);

        if (attributeIndex === undefined || attributeIndex === -1) {
          return prevAttributes;
        }

        return Object.fromEntries(
          Object.entries(prevAttributes).map(([key, value]) => {
            const currentIndex = partType.attributes?.findIndex((attr) => attr.name === key);

            return [key, currentIndex !== undefined && currentIndex >= attributeIndex ? [] : value];
          })
        );
      });
    },
    [partType.attributes]
  );

  const breadcrumbs = useMemo(() => {
    const optionBreadcrumb = onReset
      ? {
          title: partType.name,
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            onReset();
          },
        }
      : null;

    const attributeBreadcrumbs =
      partType.attributes?.map((attr) => ({
        title: getBreadcrumbTitle(attr.name),
        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          clearSelectedAttributes(attr.name);
        },
      })) ?? [];

    if (optionBreadcrumb) {
      return [optionBreadcrumb, ...attributeBreadcrumbs];
    }

    return attributeBreadcrumbs;
  }, [clearSelectedAttributes, getBreadcrumbTitle, onReset, partType.attributes, partType.name]);

  const handleSelectValue = useCallback(
    (name: string, value: string) => {
      setSelectedAttributes({ ...selectedAttributes, [name]: [value] });
    },
    [selectedAttributes]
  );

  const attribute = isFirstAttribute ? activeAttribute : activeDependentAttribute;

  const popularDependentAttribute = useMemo(
    () => popularAttributes[activeAttribute?.name ?? ''],
    [activeAttribute?.name]
  );

  const dependentAttribute = useMemo(() => {
    if (isFirstAttribute) {
      if (vehicle) {
        return {
          name: `Vehicle-specific ${popularDependentAttribute?.label ?? activeAttribute?.name}`,
          values: activeDependentAttribute?.values,
        };
      }

      if (popularDependentAttribute) {
        return {
          name: `Popular ${popularDependentAttribute.label}`,
          values: popularDependentAttribute.values,
        };
      }
    }

    return activeDependentAttribute;
  }, [activeAttribute?.name, activeDependentAttribute, isFirstAttribute, popularDependentAttribute, vehicle]);

  return {
    attribute,
    breadcrumbs,
    dependentAttribute,
    isFetching,
    isFirstAttribute,
    isLastAttribute,
    selectedAttributes,
    selectValue: handleSelectValue,
  };
};
