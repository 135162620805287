import { Icon, Typography } from '@partstech/ui';
import { entries, groupBy } from '@partstech/ui/utils';
import React, { useCallback, useMemo, useState } from 'react';
import { Asset } from './Asset';
import { styles } from './Assets.styles';
import type { Product } from 'models';

type Props = {
  attachments: Product['attachments'];
  onAssetsClick?: () => void;
};

export const Assets: React.FunctionComponent<Props> = ({ attachments, onAssetsClick }) => {
  const [activeAssetIndex, setActiveAssetIndex] = useState<number | null>(null);

  const toggleAsset = useCallback(
    (index: number) => () => {
      setActiveAssetIndex(index === activeAssetIndex ? null : index);
      onAssetsClick && onAssetsClick();
    },
    [activeAssetIndex, onAssetsClick]
  );

  const groups = useMemo(
    () =>
      entries(groupBy(attachments, 'group')).map(([name, items]) => ({
        name,
        files: items.map((item) => ({
          filename: item.filename,
          full: item.url,
        })),
      })),
    [attachments]
  );

  return (
    <div css={styles.assets}>
      {groups.map((item, index) => {
        const isActive = activeAssetIndex === index;

        return (
          <div css={styles.asset} key={index}>
            <button css={styles.button(isActive)} onClick={toggleAsset(index)}>
              <Typography variant="bodyText2" component="span">
                {item.name}
              </Typography>
              <Icon name={isActive ? 'expand_less' : 'expand_more'} />
            </button>
            {isActive && (
              <div css={styles.list}>
                {item.files.map((file, key) => (
                  <Asset file={file.full} key={key}>
                    {file.filename}
                  </Asset>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
