import { memo } from 'react';
import { CartProviderOld } from 'app/CartProviderOld';
import { useForbiddenDialog } from 'components/Modal/useForbiddenDialog';
import { SupplierSelectorProvider } from 'components/SupplierSelector/SupplierSelectorProvider';
import { CartProvider } from 'features/cart';
import { useCompareQuoteInitialize } from 'features/comparedQuote';
import { AddingLaborProvider } from 'features/labor';
import { useSubscriptionDowngradeReminder } from 'features/subscription';
import { useInactivityReload } from 'hooks/app';
import { useIdentifyUserpilot } from 'integrations/userpilot';
import { SearchResultsProvider } from 'pages/SearchResults/SearchResultsProvider';
import { SearchBarProvider } from 'widgets/searchBar';
import { LayoutProvider } from '../LayoutProvider';
import { useCardExpiryNotification } from './CardExpiryNotification';
import { useFullstory } from './useFullStory';
import { useGetUserpilotParams } from './useGetUserpilotParams';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  isIdentifiedFullstory: boolean;
}>;

export const AuthorizedProvider = memo(({ isIdentifiedFullstory, children }: Props) => {
  useInactivityReload();
  useCompareQuoteInitialize();
  useForbiddenDialog();
  useCardExpiryNotification();
  useSubscriptionDowngradeReminder();

  const { userid, parameters, isReady } = useGetUserpilotParams();

  useIdentifyUserpilot({ userid, parameters }, { skip: !isReady });

  useFullstory({ isIdentified: isIdentifiedFullstory });

  return (
    <LayoutProvider>
      <CartProviderOld>
        <CartProvider>
          <SearchResultsProvider>
            <SearchBarProvider>
              <AddingLaborProvider>
                <SupplierSelectorProvider>{children}</SupplierSelectorProvider>
              </AddingLaborProvider>
            </SearchBarProvider>
          </SearchResultsProvider>
        </CartProvider>
      </CartProviderOld>
    </LayoutProvider>
  );
});
