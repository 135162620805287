import { isNotNull, uniqBy } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { SubscriptionProductAlias, SubscriptionSubscriptionPlanStatus } from 'shared/api';
import { useOnboardSteps } from 'store/queries/currentUser/useGetOnBoardSteps';
import { useGetSubscription } from '../api';
import { ADDONS, PLANS, ProductAlias } from '../constants';
import type { SubscriptionProduct } from '../types';

const isStatusExpires = (status?: SubscriptionSubscriptionPlanStatus) =>
  status === SubscriptionSubscriptionPlanStatus.CancelRequested;

export const useSubscriptionMain = () => {
  const { isOnboardCompleted } = useOnboardSteps();

  const { isFetching, isLoading, subscription: shopSubscription } = useGetSubscription(!isOnboardCompleted);

  const subscriptionProducts = useMemo(() => shopSubscription?.plans ?? [], [shopSubscription?.plans]);

  const isTiresAddOnExpires = useMemo(
    () =>
      subscriptionProducts.some(
        ({ plan, status }) => plan?.product?.alias === SubscriptionProductAlias.Tires && isStatusExpires(status)
      ),
    [subscriptionProducts]
  );

  const products: SubscriptionProduct[] = useMemo(() => {
    const newSubscriptionProducts = subscriptionProducts
      .map((subscriptionProduct) => {
        const { downgradeProductAlias, plan, status } = subscriptionProduct;

        const planALias = plan?.product?.alias;
        const product = [...PLANS, ...ADDONS].find((PRODUCT) => (PRODUCT.alias as unknown) === planALias);

        const isExpires = planALias === SubscriptionProductAlias.Tires ? isTiresAddOnExpires : isStatusExpires(status);
        const isPlan = Boolean(plan?.product?.isPlan);

        if (!product || !plan) {
          return null;
        }

        return {
          alias: product.alias,
          amount: plan.amount,
          downgradeProductAlias:
            isExpires && isPlan && !downgradeProductAlias ? ProductAlias.Free : downgradeProductAlias,
          id: plan.id,
          isActive: status === SubscriptionSubscriptionPlanStatus.Active,
          isExpires,
          isPlan,
          logo: product.logo,
          name: product.name,
          reasons: plan.product?.unsubscribeReasons,
        };
      })
      .filter(isNotNull);

    return uniqBy(newSubscriptionProducts, 'alias');
  }, [isTiresAddOnExpires, subscriptionProducts]);

  const nextPaymentAmount = useMemo(() => {
    if (isTiresAddOnExpires) {
      return 0;
    }

    return subscriptionProducts.reduce(
      (accumulator, product) =>
        isStatusExpires(product.status) ? accumulator : accumulator + (product.plan?.amount ?? 0),
      0
    );
  }, [isTiresAddOnExpires, subscriptionProducts]);

  const subscription = useMemo(
    () => ({
      addOns: products.filter((plan) => !plan.isPlan),
      currentPeriodEnd: shopSubscription?.currentPeriodEnd ?? null,
      nextPaymentAmount,
      period: shopSubscription?.period,
      plan: products.find((plan) => plan.isPlan),
      startedAt: shopSubscription?.startedAt,
    }),
    [shopSubscription, products, nextPaymentAmount]
  );

  const getSupplierById = useCallback(
    (id: string) => {
      const supplier = subscriptionProducts.find((plan) => plan.account?.id === id);

      return {
        planId: supplier?.plan?.id ?? null,
        isExpires: isStatusExpires(supplier?.status),
      };
    },
    [subscriptionProducts]
  );

  return {
    isFetching,
    isLoading,
    subscription,
    getSupplierById,
  };
};
