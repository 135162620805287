import { FullStory } from '@fullstory/browser';
import { useCallback } from 'react';
import type { FullStoryEventNames } from '../constant';

type FullStoryEventValue =
  | boolean
  | string
  | number
  | Date
  | boolean[]
  | string[]
  | number[]
  | Date[]
  | null
  | undefined
  | Record<string, FullStoryEventValue>[];

export type FullStoryEventPayload = Record<string, FullStoryEventValue> & {
  products?: Record<string, FullStoryEventValue>[];
};

type Args = Partial<{
  shopID: string;
  userID: string;
}>;

type Params = {
  isDevMode?: boolean;
};

export const useTracking = (trackingData: Args, { isDevMode }: Params = {}) =>
  useCallback(
    (eventName: FullStoryEventNames, payload: FullStoryEventPayload = {}) => {
      if (isDevMode) {
        return;
      }

      if (payload.products) {
        FullStory.event(eventName, {
          ...trackingData,
          products: payload.products.map((product) => ({
            ...product,
            ...trackingData,
          })),
        });
      } else {
        FullStory.event(eventName, trackingData);
      }
    },
    [isDevMode, trackingData]
  );
