import { entries } from '@partstech/ui/utils';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { CartAddingStatus } from 'constant';
import { ErrorCode, ResponseError } from 'shared/api';
import { productApi, selectProductById } from 'store/entities/product';
import { selectRootState } from 'store/utils';
import { selectId } from 'utils';
import { consumeAddToCart, consumeAddToCartMultiple } from '../cart';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import type { ProductEntity } from 'types/product';

type ProductStatus = {
  status: CartAddingStatus | null;
  error: string | null;
  variation: string | null;
  quantity?: number;
  searchResultPosition?: number;
};

type State = Record<string, ProductStatus>;

const initialState: State = {};

const getFirstVariationId = (entity: ProductEntity) => entity.variations[0]?.variationId ?? null;

const productStatusesSlice = createSlice({
  name: 'productStatuses',
  initialState,
  reducers: {
    setProductError: (state, action: PayloadAction<{ productId: string; error: string | null }>) => {
      const productStatus = state[action.payload.productId];
      if (!productStatus) {
        return;
      }

      productStatus.error = action.payload.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(consumeAddToCart.pending, (state, action) => {
        const productId = selectId(action.meta.arg.product);
        const productStatus = state[productId];

        if (!productStatus) {
          return;
        }

        productStatus.error = null;
        productStatus.status = null;
      })
      .addCase(consumeAddToCart.rejected, (state, action) => {
        const { product, quantity, searchResultPosition } = action.meta.arg;
        const productId = selectId(product);

        if (!state[productId]) {
          state[productId] = {
            error: null,
            status: null,
            variation: null,
          };
        }

        const productStatus = state[productId];

        if (!productStatus) {
          return;
        }

        if (action.payload instanceof ResponseError) {
          const { code, message } = action.payload.error();

          productStatus.error = message || null;
          productStatus.status =
            code === ErrorCode.FAILED_CHECK_AVAILABILITY
              ? CartAddingStatus.ADDING_FAIL_REMOTE
              : CartAddingStatus.ADDING_FAIL;
        } else {
          productStatus.status = CartAddingStatus.ADDING_FAIL;
        }
        productStatus.quantity = quantity;
        productStatus.searchResultPosition = searchResultPosition;
      })
      .addCase(consumeAddToCartMultiple.pending, (state, action) => {
        const payloads = action.meta.arg;

        payloads.forEach((productPayload) => {
          const productId = selectId(productPayload.product);
          const productStatus = state[productId];

          if (!productStatus) {
            return;
          }

          productStatus.error = null;
          productStatus.status = null;
        });
      })
      .addCase(consumeAddToCartMultiple.rejected, (state, action) => {
        const payloads = action.meta.arg;

        payloads.forEach((productPayload) => {
          const { quantity, searchResultPosition, product } = productPayload;
          const productId = selectId(product);
          const productStatus = state[productId];

          if (!productStatus) {
            return;
          }

          productStatus.error = 'We are having issues getting information from the supplier, please try again later';
          productStatus.status = CartAddingStatus.ADDING_FAIL;
          productStatus.quantity = quantity;
          productStatus.searchResultPosition = searchResultPosition;
        });
      })
      .addCase(consumeAddToCartMultiple.fulfilled, (state, action) => {
        const payloads = action.meta.arg;
        const responses = action.payload;

        payloads.forEach((productPayload, index) => {
          const { quantity, searchResultPosition, product } = productPayload;
          const productId = selectId(product);

          if (!state[productId]) {
            state[productId] = {
              variation: getFirstVariationId(product),
              error: null,
              status: null,
            };
          }

          const productStatus = state[productId];

          if (!productStatus) {
            return;
          }

          const response = responses[index];
          if (!response) {
            return;
          }

          if (response.status === 200) {
            return;
          }

          const error = response.error?.errors[0];

          if (error) {
            productStatus.error = error.message || null;
            productStatus.status =
              error.code === ErrorCode.FAILED_CHECK_AVAILABILITY
                ? CartAddingStatus.ADDING_FAIL_REMOTE
                : CartAddingStatus.ADDING_FAIL;
          } else {
            productStatus.error = 'Not enough in stock';
            productStatus.status = CartAddingStatus.ADDING_FAIL;
          }

          productStatus.quantity = quantity;
          productStatus.searchResultPosition = searchResultPosition;
        });
      })
      .addMatcher(productApi.endpoints.GetProduct.matchFulfilled, (state, action) => {
        const productId = selectId(action.payload);
        const currentVariation = state[productId]?.variation;

        state[productId] = {
          variation: currentVariation ?? getFirstVariationId(action.payload),
          error: null,
          status: null,
        };
      });
  },
});

export const productStatuses = productStatusesSlice.reducer;

export const { setProductError } = productStatusesSlice.actions;

const selectState = (state: RootState) => state.features.search.productStatuses;

export const selectProductsWithError = createSelector([selectState, selectRootState], (state, rootState) =>
  entries(state)
    .filter(([_, status]) => status.error)
    .map(([id, status]) => ({ status, product: selectProductById(rootState, id) }))
);
