import { css } from '@emotion/react';
import { Box, Button, Card, Icon, ImageFallback, Link, Portal, Typography, useMedia } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Images360 } from 'components/Product/FileViewerModal/Images360';
import { useSettings } from 'hooks/app';
import { Assets } from './Assets';
import { DiagramsButton } from './DiagramsButton';
import { FilesCarousel } from './FilesCarousel';
import { FileViewerNavigation } from './FileViewerNavigation';
import type { Theme } from '@partstech/ui';
import type { Product } from 'models';
import type { MouseEvent } from 'react';

const styles = {
  video: css`
    border: 0;
    width: 100%;
    height: 100%;

    @media print {
      display: none;
    }
  `,
  zoomImage: (theme: Theme) => css`
    img {
      max-width: ${theme.sizing(100)};
      max-height: ${theme.sizing(100)};
      padding: ${theme.sizing(5)};
    }
  `,

  logo: (shownCarusel: boolean, shownDiagramButton: boolean) => (theme: Theme) => css`
    max-height: ${theme.sizing(6)};
    max-width: 100%;
    z-index: ${theme.zIndex.default};

    ${shownCarusel &&
    `position: absolute;
    top: ${theme.sizing(shownDiagramButton ? 34 : 18)};
    right: 0;`}
  `,
  imageSlide: (height: number) => css`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    max-width: 100%;
    max-height: ${height}px;
  `,
  imageWrap: css`
    > div:first-of-type {
      width: 100%;
      height: 100%;
    }
  `,
  button: (theme: Theme) => css`
    height: auto;
    padding: ${theme.sizing(1)};

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
};

type Props = {
  product: Product;
  defaultCurrent?: number;
  isWindow?: boolean;
  height?: number;
  hideCarousel?: boolean;
  useAssets?: boolean;
  useBigImages?: boolean;
  useDiagrams?: boolean;
  hideButtonNavigations?: boolean;
  partLink?: string;
  isShowBrandLogo?: boolean;
  isShowOnlyImage?: boolean;
  showIncreaseButton?: boolean;
  onAssetsClick?: () => void;
  onImageClick?: (imageIndex: number) => void;
};

export const FileViewer = ({
  product,
  defaultCurrent = 0,
  isWindow = false,
  height = 314,
  hideCarousel = false,
  useAssets = true,
  useBigImages = false,
  useDiagrams = true,
  hideButtonNavigations = false,
  isShowBrandLogo = false,
  isShowOnlyImage = false,
  showIncreaseButton = false,
  onAssetsClick = () => {},
  onImageClick = () => {},
  partLink,
}: Props) => {
  const { isMobile, isPrint } = useMedia();

  const { shopDomain } = useSettings();

  const [current, setCurrent] = useState(defaultCurrent);

  const { isOpen: isMouseOver, open: handleMouseOver, close: handleMouseOut } = useOpen();
  const { isOpen: isZoomEnabled, open: handleZoomEnter, close: handleZoomLeave } = useOpen();

  const imageViewerRef = useRef<HTMLDivElement>(null);
  const { image360Groups } = product.images;

  const files = useMemo(
    () => (isShowOnlyImage ? product.extractImages() : product.extractFiles()),
    [product, isShowOnlyImage]
  );
  const images360 = useMemo(
    () => image360Groups.flatMap((imageGroup) => imageGroup.map((image) => image.full ?? '')).reverse(),
    [image360Groups]
  );

  const currentFile = files[current];
  const hasImages360Groups = image360Groups.length > 0;
  const isVideo = currentFile?.isVideo;

  useEffect(
    () => () => {
      setCurrent(0);
    },
    [product]
  );

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      onImageClick(current);
    },
    [current, onImageClick]
  );

  const handleCarouselFileClick = useCallback(
    (currentIndex: number, isView360?: boolean) => {
      if (isView360 && !isMobile) {
        onImageClick?.(currentIndex);
        return;
      }

      setCurrent(currentIndex);
    },
    [isMobile, onImageClick]
  );

  const getZoomPosition = useCallback(() => {
    const rect = imageViewerRef.current?.getBoundingClientRect();

    if (!rect) {
      return {};
    }

    return { top: rect.bottom, left: rect.left + rect.width + 10 };
  }, [imageViewerRef]);

  const alt = `${product.brand?.name} ${product.partNumber?.partNumber ?? ''} ${product.partTypeName ?? ''}${
    useBigImages && ' (full size)'
  }`;

  const shownDiagramButton = useDiagrams && !isShowOnlyImage && !isPrint;
  const src = useBigImages ? (currentFile?.full ?? '') : currentFile?.medium || currentFile?.full || '';

  return (
    <Box
      position="relative"
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      ref={imageViewerRef}
      data-testid="fileViewer"
    >
      {shownDiagramButton && (
        <DiagramsButton
          vehicleId={product.vehicleId}
          diagramCategoryId={product.getDiagramCategoryId()}
          diagramId={product.getDiagramId()}
        />
      )}

      {isShowBrandLogo &&
        (product.brand?.logo ? (
          <ImageFallback
            alt={`${product.brand.name} logo`}
            title={`${shopDomain} profile page for ${product.brand.name}`}
            src={product.brand.logo ?? ''}
            css={styles.logo(!hideCarousel, shownDiagramButton)}
          />
        ) : (
          <Typography variant="caption">{product.brand?.name}</Typography>
        ))}

      {!isPrint && !hideCarousel && (files.length > 1 || hasImages360Groups) && (
        <FilesCarousel
          files={files}
          has360Images={hasImages360Groups}
          currentIndex={current}
          onClick={handleCarouselFileClick}
          alt={alt}
        />
      )}

      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height={`${height}px`}
        css={styles.imageWrap}
      >
        {isVideo && <iframe css={styles.video} allowFullScreen src={currentFile.full} title="video" />}
        {!isVideo && currentFile && (
          <Link fill to={partLink ?? '#'} onClick={isWindow ? handleClick : undefined}>
            <ImageFallback
              css={styles.imageSlide(height)}
              alt={`${alt} #${current + 1}`}
              src={src}
              data-testid="currentImage"
            />
          </Link>
        )}
        {!currentFile && hasImages360Groups && <Images360 images={images360} />}

        {!isPrint && !hideButtonNavigations && (isMobile || isMouseOver) && files.length > 1 && (
          <FileViewerNavigation
            currentIndex={current}
            setCurrentIndex={setCurrent}
            filesCount={hasImages360Groups && isMobile ? files.length + 1 : files.length}
          />
        )}

        {!isPrint &&
          !isVideo &&
          isMouseOver &&
          showIncreaseButton &&
          !isMobile &&
          !currentFile?.preview.includes('no-image') && (
            <Box position="absolute" top={19} left={24}>
              <Button
                variant="text"
                css={styles.button}
                onMouseEnter={handleZoomEnter}
                onMouseLeave={handleZoomLeave}
                noHover
              >
                <Icon name="search" size="large" color="subtleText" />
              </Button>

              {isZoomEnabled && (
                <Portal group="zoom-image">
                  <Card
                    position="fixed"
                    zIndex="overview"
                    elevation={6}
                    top={`${getZoomPosition().top}px`}
                    left={`${getZoomPosition().left}px`}
                    css={styles.zoomImage}
                  >
                    <ImageFallback loading="lazy" src={currentFile?.full ?? ''} alt="" />
                  </Card>
                </Portal>
              )}
            </Box>
          )}
      </Box>

      {useAssets && product.attachments && product.attachments.length > 0 && (
        <Box mt={5}>
          <Assets attachments={product.attachments} onAssetsClick={onAssetsClick} />
        </Box>
      )}
    </Box>
  );
};
