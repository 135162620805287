import { useCallback, useMemo } from 'react';
import { ADDONS, PLAN_COMPLETE, PLAN_FREE, ProductAlias } from '../constants';
import type { AddOn, FeatureEX, Plan, SubscriptionProduct } from '../types';

const createSubscriptionProduct = (product: Plan | AddOn): SubscriptionProduct => {
  const { alias, id, isExpires, logo, price, name, reasons } = product;

  return {
    alias,
    amount: price,
    downgradeProductAlias: null,
    id,
    isActive: true,
    isExpires,
    isPlan: [ProductAlias.Complete, ProductAlias.Plus, ProductAlias.Free].includes(alias),
    logo,
    name,
    reasons,
  };
};

type Args = {
  featuresEX: FeatureEX[];
  isRestrictedEX: boolean;
  subscriptionAddOns: SubscriptionProduct[];
  subscriptionPlan: SubscriptionProduct | undefined;
};

export const usePlanAndAddOns = ({ featuresEX, isRestrictedEX, subscriptionAddOns, subscriptionPlan }: Args) => {
  const hasFeaturesEX = featuresEX.length > 0;

  const isFeatureEX = useCallback(
    (addOn: AddOn) => featuresEX.some((feature) => feature.alias === addOn.alias),
    [featuresEX]
  );

  const addOnsEX: SubscriptionProduct[] = useMemo(
    () => ADDONS.filter(isFeatureEX).map(createSubscriptionProduct),
    [isFeatureEX]
  );

  const addOns: SubscriptionProduct[] = useMemo(() => {
    if (isRestrictedEX) {
      return [...addOnsEX, ...subscriptionAddOns];
    }

    return subscriptionAddOns;
  }, [addOnsEX, isRestrictedEX, subscriptionAddOns]);

  const plan: SubscriptionProduct = useMemo(() => {
    if (isRestrictedEX && hasFeaturesEX) {
      return createSubscriptionProduct(PLAN_COMPLETE);
    }

    return subscriptionPlan ?? createSubscriptionProduct(PLAN_FREE);
  }, [hasFeaturesEX, isRestrictedEX, subscriptionPlan]);

  return {
    addOns,
    plan,
  };
};
