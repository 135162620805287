import { css } from '@emotion/react';
import { Box, Button, Dropdown, Icon, Menu, MenuAction, MenuItem, TextField, Typography } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback, useEffect } from 'react';
import { EditLaborRateModal } from 'components/EditLaborRateModal';
import { currency } from 'shared/lib/string';
import type { LaborRate } from 'shared/api';

const styles = {
  dropdown: css`
    cursor: pointer;
    input {
      cursor: pointer;
    }
  `,
};

type Props = {
  laborRates: LaborRate[];
  selectedLaborRate?: LaborRate;
  onSelectLaborRate: (laborRateId: string) => void;
};

export const LaborRateDropdown = ({ laborRates, selectedLaborRate, onSelectLaborRate }: Props) => {
  const handleSelect = useCallback(
    (newLaborRateId: string) => () => {
      onSelectLaborRate(newLaborRateId);
    },
    [onSelectLaborRate]
  );

  const { open } = useCreateModal(
    EditLaborRateModal,
    { size: 'medium', showHelperText: true, onAdd: onSelectLaborRate },
    { customId: 'editLaborRate' }
  );

  const handleClick = useCallback(() => open(), [open]);

  useEffect(() => {
    if (!selectedLaborRate && laborRates[0]) {
      const defaultLaborRate = laborRates.find((laborRate) => laborRate.isDefault) || laborRates[0];
      onSelectLaborRate(defaultLaborRate.id);
    }
  }, [laborRates, onSelectLaborRate, selectedLaborRate]);

  if (laborRates.length === 0) {
    return (
      <Box mt={4}>
        <Button data-testid="addLaborRateButton" leadingIcon="add" variant="secondary" onClick={handleClick} fullWidth>
          Add labor rate
        </Button>
      </Box>
    );
  }

  return (
    <Dropdown
      renderButton={({ isOpened, toggleHandler }) => (
        <div css={styles.dropdown} onClick={toggleHandler} role="presentation">
          <TextField
            suffix={<Icon name={isOpened ? 'expand_less' : 'expand_more'} />}
            label="Labor rate"
            readOnly
            value={currency(selectedLaborRate?.rate || 0)}
            data-testid="laborRateDropdown"
          />
        </div>
      )}
      preferPlacement="bottom-end"
    >
      {({ closeHandler }) => (
        <Box width={72}>
          <Menu
            onSelect={closeHandler}
            footer={
              <MenuAction trailingIcon="add" onClick={handleClick}>
                Add labor rate
              </MenuAction>
            }
            fullWidth
          >
            {laborRates.map((laborRate) => (
              <MenuItem key={laborRate.id} onClick={handleSelect(laborRate.id)}>
                <Typography>{currency(laborRate.rate)}</Typography>
                <Typography variant="bodyText2" color="subtleText">{`${laborRate.name}${
                  laborRate.isDefault ? ' (Default)' : ''
                }`}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </Dropdown>
  );
};
