import { useMemo } from 'react';
import { createFullProductFromQuery } from 'factories/product/createFullProductFromQuery';
import { useFailedProductTracking } from 'hooks/product';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { api as generatedApi } from 'store/queries/product/GetProduct.generated';
import type { GetProductQueryVariables } from 'store/queries/product/GetProduct.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetProduct: {
      providesTags: ['Product'],
    },
  },
});

const { useGetProductQuery } = api;

export const useProduct = (props: GetProductQueryVariables, skip: boolean = false) => {
  const { accounts } = useSupplierAccounts();
  const { currentData, refetch, isFetching, isError, isSuccess } = useGetProductQuery(props, { skip });
  const account = useMemo(() => accounts.find((item) => item.id === props.accountId), [accounts, props.accountId]);

  const product = useMemo(() => {
    const data = currentData?.product;

    return data
      ? createFullProductFromQuery({
          product: data,
          account,
          vehicleId: props.vehicleId,
        })
      : null;
  }, [currentData?.product, account, props.vehicleId]);

  useFailedProductTracking(isError, {
    partnumberid: props?.partNumberId ? props?.partNumberId : '',
    credentialId: props?.accountId ? Number(props.accountId) : undefined,
    linecard: props?.lineCardId ? Number(props.lineCardId) : undefined,
    part_term: props?.partTypeId ? Number(props.partTypeId) : undefined,
    vehicle: props?.vehicleId ? Number(props.vehicleId) : undefined,
    vin: props?.vin,
  });

  return useMemo(
    () => ({
      product,
      isLoading: isFetching,
      isSuccess,
      isFailed: isError,
      refetch,
    }),
    [isError, isFetching, isSuccess, product, refetch]
  );
};
