import { usePosition, usePrevious } from '@partstech/ui/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useCartOld } from 'app/CartProviderOld/CartContext';
import { useCart } from 'features/cart';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useAppSelector } from 'store';
import { selectPartStatuses } from 'store/features/cart';

export const useSavedQuotesIcon = () => {
  const {
    flags: { newCart },
  } = useLaunchDarkly();

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const wrapRef = useRef<HTMLDivElement>(null);

  const partStatuses = useAppSelector(selectPartStatuses, shallowEqual);
  const partStatusesValues = useMemo(() => Object.values(partStatuses), [partStatuses]);

  const { isLoading: isLoadingOld } = useCartOld();
  const { isLoading: isFirstLoading, isFetching: isFetchingNew, isRemoving, cart } = useCart();
  const isLoading = newCart ? (isFetchingNew || isRemoving) && !isFirstLoading : isLoadingOld;

  const [showAnimation, setShowAnimation] = useState(false);

  const partsCount = useMemo(
    () => (newCart ? cart?.partsCount() || 0 : partStatusesValues.length),
    [cart, newCart, partStatusesValues.length]
  );

  const isCartEmpty = partsCount === 0;
  const isCartEmptyPrev = usePrevious(isCartEmpty);
  const isFirstAdding = isCartEmptyPrev && !isCartEmpty;

  const { position } = usePosition({ contentRef, wrapRef, preferPlacement: 'top-start', offset: -7 });

  useEffect(() => {
    if (isFirstAdding) {
      setShowAnimation(true);

      timerRef.current = setTimeout(() => {
        setShowAnimation(false);
      }, 2000);
    }
  }, [isCartEmptyPrev, isFirstAdding]);

  useEffect(() => {
    if (!showAnimation && timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, [showAnimation]);

  return { wrapRef, contentRef, position, showAnimation, isLoading: isLoading && !isCartEmptyPrev };
};
