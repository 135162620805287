import { entries, keys, uniq } from '@partstech/ui/utils';
import { PartTypeApplication } from 'shared/api';
import { PartType } from './PartType';
import type { Vehicle } from './Vehicle';

type SelectedAttribute = Record<string, string[]>;

export class UniversalPartType extends PartType {
  selectedAttributes: SelectedAttribute = {};

  hasAttributes() {
    return (this.attributes ?? []).length > 0;
  }

  isAllAttributesFilled() {
    return (this.attributes ?? []).length === keys(this.selectedAttributes).length;
  }

  hasMultipleAttributes() {
    return (this.attributes ?? []).length > 1;
  }

  hasSomeFilledAttributes(): Boolean {
    return Boolean(Object.values(this.selectedAttributes).find(([_, values]) => Boolean(values)));
  }

  setSelectedAttributes(attributes: SelectedAttribute) {
    this.selectedAttributes = attributes;

    return this;
  }

  shouldPickAttributes(hasVehicle: boolean): boolean {
    if (this.hasMultipleAttributes() && !this.isAllAttributesFilled()) {
      return true;
    }

    if (hasVehicle) {
      return false;
    }

    return !this.isAllAttributesFilled();
  }

  getSelectedAttributes() {
    return entries(this.selectedAttributes).reduce<SelectedAttribute>(
      (acc, [name, values]) => ({
        ...acc,
        [name]: uniq(values),
      }),
      {}
    );
  }

  toQuery(vehicle: Vehicle | null, isFittedAvailable: boolean = false) {
    return {
      part_text_id: this.id,
      part_text: this.name,
      attributes: !isFittedAvailable ? this.getSelectedAttributes() : undefined,
      fit: this.application === PartTypeApplication.UniversalFitted && vehicle && isFittedAvailable ? ['1'] : undefined,
    };
  }
}
