import { isEmpty } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { UniversalPartType } from 'models';
import { formatPartTypesQuery, getApplicationPartTypeId } from 'utils';
import { useSearchParams } from './useSearchParams';
import type { DynamicAttributes } from 'types/search';

type UseNonAppAttributesReturn = {
  shouldPickAttributes: boolean;
  attributes: DynamicAttributes;
  isReady: boolean;
};

export const useNonAppAttributes = (): UseNonAppAttributesReturn => {
  const {
    flags: { fluidsChemicalsTab },
  } = useLaunchDarkly();

  const { searchParams, partType, isReady } = useSearchParams();

  const partTypeId = useMemo(
    () => getApplicationPartTypeId(searchParams.part_text_id, formatPartTypesQuery(searchParams.part_types ?? [])),
    [searchParams.part_text_id, searchParams.part_types]
  );

  const shouldPickAttributes = useMemo(() => {
    if (partTypeId && isReady && !partType) {
      return true;
    }

    if (partType?.application) {
      if (partType.attributes === null) {
        return false;
      }

      if (
        fluidsChemicalsTab &&
        partType instanceof UniversalPartType &&
        partType.shouldPickAttributes(Boolean(searchParams.vehicle))
      ) {
        return !searchParams.attributes;
      }

      if (partType.isUniversalFitted && searchParams.vehicle && isEmpty(partType.attributes)) {
        return false;
      }

      if (partType.isUniversalFitted && !searchParams.vehicle) {
        return !searchParams.attributes;
      }
    }

    return false;
  }, [partTypeId, isReady, partType, fluidsChemicalsTab, searchParams.vehicle, searchParams.attributes]);

  const dynamicAttributes = useMemo(
    () =>
      partType?.attributes
        ?.filter((attr) => attr.required)
        .reduce<DynamicAttributes>(
          (result, attribute) => ({ ...result, [attribute.name]: attribute.values ?? [] }),
          {}
        ) ?? {},
    [partType?.attributes]
  );

  return { shouldPickAttributes, attributes: dynamicAttributes, isReady };
};
