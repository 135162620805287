import { isNotNull } from '@partstech/ui/utils';
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { createProductFromData } from 'factories';
import { shopApi } from 'shared/api';
import { getShipments } from 'store/actions/cart';
import { getMissingStores, selectStoreById } from 'store/entities/store';
import { getMissingSuppliers, selectSupplierById } from 'store/entities/supplier';
import { consumeAddToCart, consumeAddToCartMultiple } from 'store/features/cart';
import { selectOutTheDoorPricesByProductId } from 'store/features/search/outTheDoorPrices';
import { logout } from 'store/features/user/account';
import { selectRootState } from 'store/utils';
import { getAttributesByProductAndVariation, selectId } from 'utils';
import type { EntityId, PayloadAction } from '@reduxjs/toolkit';
import type { AttributeUsageSection } from 'constant';
import type { RootState } from 'store';
import type { Product, ProductAttribute, ProductEntity, ProductParams, ProductQueryParams } from 'types/product';

export type Rebate = {
  couponLink: string | null;
  description: string;
  legalLink: string | null;
  price: number;
  text: string;
  title: string;
  validDate: string | null;
};

export const productApi = shopApi.injectEndpoints({
  endpoints: (build) => ({
    GetProduct: build.query<Product, ProductQueryParams>({
      query: ({ partnumberid, ...params }) => ({
        url: `/details/${partnumberid}`,
        params: { ...params, vehicle: params.vehicle ? Number(params.vehicle) : undefined },
      }),
      providesTags: () => [{ type: 'Product' }],
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;

          const { supplierId, storeId } = data;

          if (storeId) {
            dispatch(getMissingStores([storeId]));
          }

          if (supplierId) {
            dispatch(getMissingSuppliers([String(supplierId)]));
          }
        } catch {
          /* empty */
        }
      },
    }),
  }),
});

export const { useLazyGetProductQuery } = productApi;

const productAdapter = createEntityAdapter<ProductEntity, EntityId>({ selectId: (entity) => selectId(entity) });

type State = {
  params: Record<string, ProductParams>;
};

const initialState = productAdapter.getInitialState<State>({ params: {} });

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setPartialProduct: (state, action: PayloadAction<Product>) => {
      const { variation, store, supplier, ...entity } = action.payload;
      const hasProduct = state.ids.find((id) => id === selectId(action.payload));

      if (!hasProduct) {
        productAdapter.upsertOne(state, entity);
      }
    },
    clearProducts: productAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShipments.fulfilled, (state, action) => {
        productAdapter.upsertMany(
          state,
          action.payload.reduce(
            (memo: ProductEntity[], { parts }) => [
              ...memo,
              ...parts
                .map(({ product }) => {
                  const hasProduct = state.ids.find((id) => id === selectId(product));

                  if (hasProduct) {
                    return null;
                  }

                  return product;
                })
                .filter(isNotNull),
            ],
            []
          )
        );
      })
      .addCase(logout.fulfilled, productAdapter.removeAll)
      .addCase(consumeAddToCart.pending, (state, action) => {
        const { product } = action.meta.arg;
        const hasProduct = state.ids.find((id) => id === selectId(product));

        if (!hasProduct) {
          productAdapter.upsertOne(state, product);
        }
      })
      .addCase(consumeAddToCartMultiple.pending, (state, action) => {
        action.meta.arg.forEach(({ product }) => {
          const hasProduct = state.ids.find((id) => id === selectId(product));

          if (!hasProduct) {
            productAdapter.upsertOne(state, product);
          }
        });
      })
      .addMatcher(productApi.endpoints.GetProduct.matchFulfilled, productAdapter.upsertOne);
  },
});

export const product = productSlice.reducer;

export const { setPartialProduct } = productSlice.actions;

const selectState = (state: RootState) => state.entities.product;

const { selectById } = productAdapter.getSelectors(selectState);

export const selectProductById = createSelector(
  [selectRootState, selectById, selectOutTheDoorPricesByProductId, (_: unknown, id: EntityId | null) => id],
  (state, entity, outTheDoorPrices, id) => {
    if (!entity) {
      return null;
    }

    const statuses = state.features.search.productStatuses[id ?? ''] ?? { error: null, status: null, variation: null };

    return {
      ...entity,
      ...statuses,
      store: selectStoreById(state, entity.storeId ?? null) ?? null,
      supplier: selectSupplierById(state, entity.supplierId ? String(entity.supplierId) : null) ?? null,
      outTheDoorPrices,
    };
  }
);

export const selectProductModelById = createSelector(
  [selectProductById, (_: unknown, _productId: string, showRetailPrice?: boolean) => showRetailPrice],
  (entity, showRetailPrice) =>
    entity
      ? createProductFromData({ productData: entity, supplier: entity.supplier, store: entity.store, showRetailPrice })
      : null
);

export const selectProductModelsByIds = createSelector(
  [selectRootState, (_: unknown, ids: string[], showRetailPrice?: boolean) => ({ ids, showRetailPrice })],
  (state, { ids, showRetailPrice }) => ids.map((id) => selectProductModelById(state, id, showRetailPrice))
);

export const selectAttributesByProductId = createSelector(
  [
    (state, _section, productId: string) => selectProductById(state, productId),
    (_: unknown, section: AttributeUsageSection) => section,
    (_: unknown, _section, _productId: string, variation: string | null) => variation,
  ],
  (entity, section, variationId): ProductAttribute[] => getAttributesByProductAndVariation(entity, variationId, section)
);
