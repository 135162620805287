import { Routes } from 'constant';
import { generateUrl } from './generateUrl';

export enum SignUpPages {
  CreateAccount = 'create-account',
  HearAboutUs = 'hear-about-us',
  ManagementSystem = 'management-system',
  PartsSuppliers = 'parts-suppliers',
  PhoneNumber = 'phone-number',
  Referral = 'referral',
  Role = 'role',
  ShopDetails = 'shop-details',
  ShopType = 'shop-type',
  Success = 'success',
  TireSuppliers = 'tire-suppliers',
}

export type SignUpQueryParams = Partial<{
  code: string;
  selectedSms: string;
  state: string;
  token: string;
}>;

export const getSignUpUrl = (page: SignUpPages = SignUpPages.CreateAccount, search?: SignUpQueryParams) =>
  generateUrl(Routes.SIGN_UP, { page }, search);
