import { Box, Button } from '@partstech/ui';
import { useCallback, useMemo, type PropsWithChildren } from 'react';
import { Outlet } from 'app/AppRouter/Outlet';
import { useAppLocationsHistory } from 'app/AppRouter/useAppLocationsHistory';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useSmsConnectionsRouter } from 'hooks/smsConnections';
import { SmsConnectionsPages } from 'utils/url';
import { SmsConnectionsContext } from './SmsConnectionsContext';

const SmsConnectionsWrapper = ({ children }: PropsWithChildren) => {
  const navigate = useAppNavigate();

  const {
    pathParams: { page: smsConnectionsPage },
  } = useSmsConnectionsRouter();

  const { previousAppLocation } = useAppLocationsHistory();

  const isShowBackButton = useMemo(() => {
    if (!previousAppLocation?.pathname) {
      return false;
    }

    return [SmsConnectionsPages.Success, SmsConnectionsPages.Error, SmsConnectionsPages.Connect].every(
      (page) => page !== smsConnectionsPage
    );
  }, [previousAppLocation?.pathname, smsConnectionsPage]);

  const handleBackButtonClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      {isShowBackButton && (
        <Box position="absolute" top={{ sm: 1, md: 2 }} left={{ sm: 1, md: 6 }}>
          <Button leadingIcon="chevron_left" variant="text" onClick={handleBackButtonClick} noHover>
            Back
          </Button>
        </Box>
      )}

      <Outlet>{children}</Outlet>
    </>
  );
};

export const SmsConnectionsLayout = ({ children }: PropsWithChildren) => (
  <Box minHeight="100vh" minWidth="100vw" background="white">
    <Box
      display="flex"
      justifyContent="center"
      px={{ sm: 4, md: 6 }}
      py={{ sm: 5, md: 6 }}
      width="100%"
      height="100%"
      minHeight="100vh"
      maxWidth={192}
      mx="auto"
    >
      <SmsConnectionsContext>
        <SmsConnectionsWrapper>{children}</SmsConnectionsWrapper>
      </SmsConnectionsContext>
    </Box>
  </Box>
);
