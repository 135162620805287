import { useCallback } from 'react';
import { Routes } from 'constant';
import { FullStoryEventNames, useFullStory } from 'integrations/fullstory';
import { getProductUrl } from 'utils/url';
import type { FullShipmentPart } from 'types/shipment';

export const useRemoveFromCartTracking = () => {
  const { sendEvent } = useFullStory();

  const trackRemoveFromCart = useCallback(
    (part: FullShipmentPart) => {
      sendEvent(FullStoryEventNames.PRODUCT_REMOVED, {
        removed_from: window.location.pathname === Routes.CART ? 'cart' : 'widget',
        brand: part.product?.brandName,
        category: part.partTypeName,
        name: part.product?.title,
        price: part.product ? part.product.price : 0,
        product_id: part.product?.partNumberId,
        quantity: part.quantity,
        sku: part.product?.partNumber,
        supplier: part.product?.supplier?.name,
        url: part.product ? getProductUrl(part.product) : '',
      });
    },
    [sendEvent]
  );

  return { trackRemoveFromCart };
};
