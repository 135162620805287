import { Box, Typography } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

export const OptionsWrapper = ({ children, title }: { title?: string } & PropsWithChildren) => (
  <>
    {title && (
      <Box pt={3} pr={3} pb={0} pl={4}>
        <Typography variant="overline" color="subtleText">
          {title}
        </Typography>
      </Box>
    )}

    <Box mb={3}>{children}</Box>
  </>
);
