import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { useSignupQueryParams } from 'hooks/signup';
import { SignUpPages, getSignUpUrl } from 'utils/url';

export type SignUpStep = {
  id: SignUpPages;
  name: string;
  url: string;
  isActive: boolean;
};

type Args = {
  isMitchell1SignUp: boolean;
};

export const useSignUpSteps = ({ isMitchell1SignUp }: Args) => {
  const { pathname } = useAppLocation();

  const searchParams = useSignupQueryParams();

  const signUpSteps = useMemo<SignUpStep[]>(
    () =>
      [
        {
          id: SignUpPages.ShopType,
          name: 'Shop type',
          url: getSignUpUrl(SignUpPages.ShopType, searchParams),
          isActive: true,
        },
        {
          id: SignUpPages.ShopDetails,
          name: 'Shop details',
          url: getSignUpUrl(SignUpPages.ShopDetails, searchParams),
          isActive: true,
        },
        {
          id: SignUpPages.ManagementSystem,
          name: 'Management system',
          url: getSignUpUrl(SignUpPages.ManagementSystem, searchParams),
          isActive: !isMitchell1SignUp,
        },
        {
          id: SignUpPages.PartsSuppliers,
          name: 'Parts suppliers',
          url: getSignUpUrl(SignUpPages.PartsSuppliers, searchParams),
          isActive: true,
        },
        {
          id: SignUpPages.TireSuppliers,
          name: 'Tire suppliers',
          url: getSignUpUrl(SignUpPages.TireSuppliers, searchParams),
          isActive: true,
        },
        {
          id: SignUpPages.Role,
          name: 'Roles',
          url: getSignUpUrl(SignUpPages.Role, searchParams),
          isActive: true,
        },
        {
          id: SignUpPages.HearAboutUs,
          name: 'Hear about us',
          url: getSignUpUrl(SignUpPages.HearAboutUs, searchParams),
          isActive: true,
        },
        {
          id: SignUpPages.PhoneNumber,
          name: 'Phone number',
          url: getSignUpUrl(SignUpPages.PhoneNumber, searchParams),
          isActive: true,
        },
      ].filter((step) => step.isActive),
    [isMitchell1SignUp, searchParams]
  );

  const activeStepIndex = useMemo(
    () => signUpSteps.findIndex((step) => matchPath(step.url.split('?')[0]!, pathname)),
    [pathname, signUpSteps]
  );

  const activeStep = useMemo(() => signUpSteps[activeStepIndex] ?? signUpSteps[0]!, [activeStepIndex, signUpSteps]);

  const nextStep = useMemo(() => signUpSteps[activeStepIndex + 1] ?? null, [activeStepIndex, signUpSteps]);

  const previousStep = useMemo(() => signUpSteps[activeStepIndex - 1] ?? null, [activeStepIndex, signUpSteps]);

  return { signUpSteps, activeStep, nextStep, previousStep };
};
