import { PartTypeApplication } from 'shared/api';
import { UniversalPartType } from './UniversalPartType';
import type { Vehicle } from './Vehicle';
import type { FullPartTypeFragment } from 'store/queries/partTypes/FullPartType.generated';

export class UniversalPartTypeAlias extends UniversalPartType {
  constructor(
    partTypeData: FullPartTypeFragment,
    private alias: string
  ) {
    super(partTypeData);

    this.name = alias;
  }

  toString(): string {
    return this.alias;
  }

  populateInput(): string {
    return this.alias;
  }

  toQuery(vehicle: Vehicle | null, isFittedAvailable: boolean = false) {
    return {
      part_text_id: this.id,
      part_text: this.alias,
      attributes: !isFittedAvailable ? this.getSelectedAttributes() : undefined,
      fit: this.application === PartTypeApplication.UniversalFitted && vehicle && isFittedAvailable ? ['1'] : undefined,
    };
  }
}
