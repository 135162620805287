import { makeContext } from '@partstech/ui/utils';
import type { EntityManagementSystem } from 'features/shop';
import type { SignUpSmsConnectionFormData } from 'features/smsConnections';
import type { Dispatch, SetStateAction } from 'react';
import type { SmsRegistrationData } from 'shared/api';

type SmsConnectionsContext = {
  managementSystem: EntityManagementSystem | null;
  shopAndUserData: SignUpSmsConnectionFormData;
  setShopAndUserData: Dispatch<SetStateAction<SignUpSmsConnectionFormData>>;
  videoUrl: string;

  smsRegistrationData: SmsRegistrationData | null;
  isLoadingSmsRegistrationData: boolean;
  isErrorSmsRegistrationData: boolean;
  refetchSmsRegistrationData: () => Promise<void>;
};

export const [useSmsConnectionsContext, SmsConnectionsContextProvider] = makeContext<SmsConnectionsContext>();
