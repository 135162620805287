import { useMedia, useSnackbar } from '@partstech/ui';
import { useCallback, useEffect, useMemo } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { DEFAULT_AVAILABILITY } from 'constant';
import { useAppDispatch, useAppSelector } from 'store';
import { pickSupplierAccount, selectSelectedSupplierAccountId } from 'store/entities/supplierAccounts';
import { useGetSearchUrl } from '../search/useGetSearchUrl';
import { useHasCatalog } from '../search/useHasCatalog';
import { useIsSameSearch } from '../search/useIsSameSearch';
import { useSearchParams } from '../search/useSearchParams';
import { useGuessSupplierAccount } from './useGuessSupplierAccount';
import { useSupplierAccounts } from './useSupplierAccounts';

export const useActiveSupplierAccount = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { isTiresSearch, isDealersSearch, isCatalogSelected, selectedSupplierId, searchParams } = useSearchParams();
  const hasCatalog = useHasCatalog();
  const isSameSearch = useIsSameSearch();
  const { isMobile } = useMedia();
  const { getSearchUrl } = useGetSearchUrl();
  const { addSnackbar } = useSnackbar();

  // TODO: Replace after completing task PD-5879
  const supplierAccountId = useAppSelector(selectSelectedSupplierAccountId);
  const selectedId = supplierAccountId ?? selectedSupplierId;

  const { accounts } = useSupplierAccounts({
    type: isTiresSearch ? 'tires' : 'parts',
  });

  const activeAccount = useMemo(
    () => accounts.find((account) => account.id === selectedId) ?? null,
    [accounts, selectedId]
  );

  const isSelectedAccountExist = Boolean((selectedId && activeAccount) || (isCatalogSelected && hasCatalog));

  const needToGuessSupplier = useMemo(() => {
    if (accounts.length === 0 && !selectedId) {
      return false;
    }

    return Boolean(!selectedId || (selectedId && !isSelectedAccountExist));
  }, [accounts.length, isSelectedAccountExist, selectedId]);

  const guessedAccount = useGuessSupplierAccount({
    type: isTiresSearch ? 'tires' : 'parts',
    supplierType: isDealersSearch ? 'dealers' : 'aftermarket',
  });

  const selectSupplier = useCallback(
    (id: string, click?: boolean) => {
      if (id === selectedId || !isSameSearch) {
        return;
      }

      dispatch(pickSupplierAccount(id));

      if (!isMobile) {
        window.scrollTo(0, 0);
      }

      if (click) {
        if (searchParams.storeAvailability) {
          addSnackbar({
            variant: 'warning',
            header: 'We removed the selected filter',
            label: `'${searchParams.storeAvailability.at(-1)}' filter was hiding a result, so we removed it to show everything.`,
          });
        }

        navigate(
          getSearchUrl({
            ...searchParams,
            selected_distributor: id,
            storeAvailability: undefined,
            availability: searchParams.availability ?? DEFAULT_AVAILABILITY,
            page: undefined,
          }),
          { replace: true }
        );
      }
    },
    [addSnackbar, dispatch, getSearchUrl, isMobile, isSameSearch, navigate, searchParams, selectedId]
  );

  useEffect(() => {
    if (!needToGuessSupplier) {
      return;
    }

    dispatch(pickSupplierAccount(guessedAccount?.id ?? null));
  }, [dispatch, guessedAccount?.id, needToGuessSupplier]);

  useEffect(() => {
    if (!isSameSearch && !selectedSupplierId) {
      dispatch(pickSupplierAccount(null));
    }
  }, [dispatch, isSameSearch, selectedSupplierId]);

  return { activeAccount, selectedId, selectSupplier };
};
