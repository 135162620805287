import { useSnackbar } from '@partstech/ui';
import { useForm } from '@partstech/ui/forms';
import { useCallback, useEffect } from 'react';
import { useValidateAddressesMutation } from 'entities/address/api/validateAddresses/ValidateAddresses.generated';
import { Country, GraphQLError, ShopType } from 'shared/api';
import { denormalizeFormData, useFormMutationCallback } from 'shared/lib/form';
import type { FieldPath, UseFormSetError } from '@partstech/ui/forms';
import type { CreateShopAndUserWithSmsConnectInput } from 'shared/api';

export type SignUpSmsConnectionFormData = Omit<CreateShopAndUserWithSmsConnectInput, 'hash'>;

const initialValues: SignUpSmsConnectionFormData = {
  address: {
    address1: '',
    address2: null,
    city: '',
    country: Country.Us,
    state: '',
    zipCode: '',
  },
  email: '',
  lastName: '',
  password: '',
  shopName: '',
  shopPhoneNumber: '',
  shopType: ShopType.GeneralRepairShop,
  website: null,
  firstName: '',
};

export type SignUpFormArgs = {
  defaultValues?: SignUpSmsConnectionFormData;
  onSubmit: (payload: SignUpSmsConnectionFormData) => void | Promise<void>;
};

export const useSignUpForm = ({ defaultValues = initialValues, onSubmit }: SignUpFormArgs) => {
  const { addSnackbar } = useSnackbar();

  const form = useForm<SignUpSmsConnectionFormData>({ defaultValues: denormalizeFormData(defaultValues) });

  const {
    reset,
    formState: { isValid, isSubmitting },
    setError,
  } = form;

  const handleFormError = useCallback<UseFormSetError<SignUpSmsConnectionFormData>>(
    (name, errorMessage) => {
      setError(name.replaceAll('billingAddress', 'address') as FieldPath<SignUpSmsConnectionFormData>, errorMessage);
    },
    [setError]
  );

  const [validateAddresses] = useFormMutationCallback(useValidateAddressesMutation, {
    onValidationError: handleFormError,
  });

  const submitForm = useCallback(
    async (payload: SignUpSmsConnectionFormData) => {
      const response = await validateAddresses({ input: { billingAddress: payload.address } });

      if (!response || response instanceof GraphQLError || !response.validateAddresses?.billingValidity) {
        addSnackbar({ label: 'Address in not valid' });
        return;
      }

      await onSubmit(payload);
    },
    [addSnackbar, onSubmit, validateAddresses]
  );

  const isLoading = isSubmitting;

  const isDisabled = !isValid || isLoading;

  useEffect(() => {
    reset(denormalizeFormData(defaultValues));
  }, [defaultValues, reset]);

  return { form, submitForm, isLoading, isDisabled };
};
