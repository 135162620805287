import { createBaseProductFromQuery } from './createBaseProductFromQuery';
import type { SupplierAccount } from 'models';
import type { FullProductFragment } from 'store/queries/product/FullProductFragment.generated';

type Params = {
  account?: SupplierAccount | null;
  product: FullProductFragment;
  vehicleId?: string | null;
};

export const createFullProductFromQuery = (params: Params) => {
  const product = createBaseProductFromQuery(params);

  const { product: fullProduct } = params;

  product.setSpecificMarketingDescriptions(fullProduct.specificMarketingDescriptions);
  product.setProductAttributes(fullProduct.productAttributes);
  product.setWarnings(fullProduct.warnings);
  product.setLinks(fullProduct.links);
  product.setAttachments(fullProduct.attachments);

  if (fullProduct.freightPackage) {
    product.setFreightPackage(fullProduct.freightPackage);
  }

  product.setExtendedInformation(fullProduct.extendedInformation);

  if (fullProduct.diagrams) {
    product.setDiagrams(fullProduct.diagrams);
  }

  if (fullProduct.interchanges) {
    product.setInterchanges(fullProduct.interchanges);
  }

  if (fullProduct.marketingDescriptions) {
    product.setMarketingDescriptions(fullProduct.marketingDescriptions);
  }

  return product;
};
