import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { ErrorFallback } from 'app/ErrorBoundary/ErrorFallback';
import { Layout } from 'components/Layout';
import { ProfiledMainLayout } from 'components/Layout/MainLayout';
import { NotFound } from 'pages/NotFound';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useOnboardSteps } from 'store/queries/currentUser/useGetOnBoardSteps';
import type { PropsWithChildren } from 'react';

const ErrorLayout = ({ children }: PropsWithChildren) => {
  const { hasCurrentUser } = useGetCurrentUser();
  const { isOnboardCompleted } = useOnboardSteps();

  if (hasCurrentUser && !isOnboardCompleted) {
    return <ProfiledMainLayout>{children}</ProfiledMainLayout>;
  }

  return (
    <ProfiledMainLayout>
      <Layout>{children}</Layout>
    </ProfiledMainLayout>
  );
};

export const ErrorElement = () => {
  const routeError = useRouteError();

  if (!isRouteErrorResponse(routeError)) {
    return (
      <ErrorLayout>
        <ErrorFallback askRefresh showButton />
      </ErrorLayout>
    );
  }

  if (routeError.status === 404) {
    return (
      <ErrorLayout>
        <NotFound />
      </ErrorLayout>
    );
  }

  return (
    <ErrorLayout>
      <ErrorFallback askRefresh showButton />
    </ErrorLayout>
  );
};
