import { css } from '@emotion/react';
import { Container, Box, Logo, ImageFallback, Icon, useMedia } from '@partstech/ui';
import { isInIframe } from '@partstech/ui/utils';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMatch } from 'react-router-dom';
import { Outlet } from 'app/AppRouter/Outlet';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { URL } from 'constant';
import { useSettings } from 'hooks/app';
import { useSelectedSmsFromQuery } from 'hooks/shopManagementSystems';
import mitchell1Logo from 'images/content/signUp/mitchell1-logo.png';
import hexes from 'images/icons/signUp/hexes.svg';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { SignUpPages, getDashboardUrl, getLoginUrl, getSignUpUrl } from 'utils/url';
import { GoogleOneTap } from './GoogleOneTap';
import type { Theme } from '@partstech/ui';
import type { PropsWithChildren } from 'react';
import type { UserAccountStatus } from 'shared/api';

const styles = {
  image: (theme: Theme) => css`
    width: ${theme.sizing(125)};
  `,
};

export const SignUpLayout = ({ children }: PropsWithChildren) => {
  const navigate = useAppNavigate();

  const { refetch: refetchCurrentUser } = useGetCurrentUser();
  const { refetch: refetchAppSettings } = useSettings();

  const { isDesktop, isWide, isMobile } = useMedia();

  const { googleClientOAuthId } = useSettings();

  const { selectedSms } = useSelectedSmsFromQuery();

  const isSignUpPage = useMatch(getSignUpUrl());
  const showGoogleOneTap = googleClientOAuthId && isSignUpPage;

  const handleSuccessGoogleOneTap = useCallback(
    async (accountStatus?: UserAccountStatus) => {
      if (!accountStatus) {
        const response = await refetchCurrentUser();

        refetchAppSettings();

        if (response.data?.currentUser?.activeMember?.shop) {
          navigate(getDashboardUrl());
          return;
        }

        navigate(getSignUpUrl(SignUpPages.ShopType));
        return;
      }

      navigate(getLoginUrl(), { state: { signUpAccountStatus: accountStatus } });
    },
    [navigate, refetchCurrentUser, refetchAppSettings]
  );

  if (isInIframe()) {
    return (
      <Box p={4}>
        <Outlet>{children}</Outlet>
      </Box>
    );
  }

  return (
    <>
      <Box width="100%" maxWidth={270} mx="auto">
        <Box
          my={0}
          mx="auto"
          minHeight="100vh"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          p={{ sm: 6, md: 10 }}
          width={{ sm: '100%', md: 'auto', lg: '100%' }}
        >
          <Helmet>
            <title>Sign Up</title>
          </Helmet>

          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ sm: 'column', lg: 'row' }}
            alignItems="center"
          >
            <Container disableGutters size="x-small">
              <Box display="flex" gap={2} flexDirection="column">
                <Box display="flex" alignItems="center" gap={5} alignSelf="center">
                  {selectedSms && (
                    <>
                      <ImageFallback src={mitchell1Logo} height={isMobile ? 27 : 36} />

                      <Icon name="swap_horiz" color="subtleText" />
                    </>
                  )}
                  <a href={URL.HOME}>
                    <Logo />
                  </a>
                </Box>

                <Outlet>{children}</Outlet>
              </Box>
            </Container>

            {(isDesktop || isWide) && (
              <div>
                <img css={styles.image} src={hexes} alt="" />
              </div>
            )}
          </Box>
        </Box>
      </Box>

      {showGoogleOneTap && <GoogleOneTap clientId={googleClientOAuthId} onSuccess={handleSuccessGoogleOneTap} />}
    </>
  );
};
