import { useMemo } from 'react';
import { useGetManagementSystems } from 'features/shop';
import { useGetSmsRegistrationData } from 'features/smsConnections';
import { useSmsConnectionsRouter } from 'hooks/smsConnections';
import { Country, ShopType } from 'shared/api';
import { SmsConnectionsContextProvider } from './SmsConnectionsContext';
import { useShopAndUserData } from './useShopAndUserData';
import { useVideoUrl } from './useVideoUrl';
import type { SignUpSmsConnectionFormData } from 'features/smsConnections';
import type { PropsWithChildren } from 'react';

export const SmsConnectionsContext = ({ children }: PropsWithChildren) => {
  const {
    pathParams: { hash },
  } = useSmsConnectionsRouter();

  const {
    smsRegistrationData,
    isLoading: isLoadingSmsRegistrationData,
    isError: isErrorSmsRegistrationData,
    refetch: refetchSmsRegistrationData,
  } = useGetSmsRegistrationData({ hash });

  const { managementSystems, isLoading: isLoadingManagementSystems } = useGetManagementSystems();

  const managementSystem = useMemo(
    () => managementSystems.find((entity) => entity.id === smsRegistrationData?.managementSystemId) ?? null,
    [managementSystems, smsRegistrationData?.managementSystemId]
  );

  const defaultValues = useMemo<SignUpSmsConnectionFormData | undefined>(() => {
    if (!smsRegistrationData) {
      return undefined;
    }

    return {
      email: smsRegistrationData.email ?? '',
      firstName: smsRegistrationData.firstName ?? '',
      lastName: smsRegistrationData.lastName ?? '',
      password: '',
      shopName: smsRegistrationData.shopName ?? '',
      shopPhoneNumber: smsRegistrationData.shopPhoneNumber ?? '',
      shopType: smsRegistrationData.shopType ?? ShopType.GeneralRepairShop,
      address: {
        address1: smsRegistrationData.address1 ?? '',
        address2: smsRegistrationData.address2,
        city: smsRegistrationData.city ?? '',
        country: smsRegistrationData.country ?? Country.Us,
        state: smsRegistrationData.state ?? '',
        zipCode: smsRegistrationData.zipCode ?? '',
      },
      website: smsRegistrationData.website ?? '',
    };
  }, [smsRegistrationData]);

  const [shopAndUserData, setShopAndUserData] = useShopAndUserData({ defaultValues });
  const videoUrl = useVideoUrl({ defaultUrl: smsRegistrationData?.video });

  const value = useMemo(
    () => ({
      managementSystem,
      shopAndUserData,
      setShopAndUserData,
      videoUrl,

      smsRegistrationData,
      isLoadingSmsRegistrationData: isLoadingSmsRegistrationData || isLoadingManagementSystems,
      isErrorSmsRegistrationData,
      refetchSmsRegistrationData: async () => {
        await refetchSmsRegistrationData();
      },
    }),
    [
      managementSystem,
      shopAndUserData,
      setShopAndUserData,
      videoUrl,
      smsRegistrationData,
      isLoadingSmsRegistrationData,
      isLoadingManagementSystems,
      isErrorSmsRegistrationData,
      refetchSmsRegistrationData,
    ]
  );

  return <SmsConnectionsContextProvider value={value}>{children}</SmsConnectionsContextProvider>;
};
