import { isNotNull } from '@partstech/ui/utils';
import { createLaborApplicationFromCartLaborItem } from 'factories/labor';
import { Cart, Vehicle } from 'models';
import { CartImprovedStatus } from 'shared/api';
import {
  createCartOrderFromActiveCart,
  createCartOrderFromActiveOrder,
  createCartOrderFromPaidOrder,
} from './createCartOrder';
import { createCartTotalsFromCartOrders } from './createCartTotals';
import type { ActiveCartRawData } from 'features/cart/api/queries';
import type { SavedCartRawData } from 'features/savedQuotes';
import type { SupplierAccount } from 'models';

const createLaborAplications = (cart: ActiveCartRawData) => {
  const unlinkedLaborAplications = cart.laborItems?.map(createLaborApplicationFromCartLaborItem) || [];

  const linkedLaborApplications =
    cart.orders
      ?.flatMap((order) => order.items?.flatMap((orderItem) => orderItem.laborItems))
      .filter(isNotNull)
      .map(createLaborApplicationFromCartLaborItem) || [];

  return [...unlinkedLaborAplications, ...linkedLaborApplications];
};

const createVehicles = (vehiclesRaw?: ActiveCartRawData['vehicles'] | null) =>
  vehiclesRaw
    ?.map((item) => (item.vehicle ? new Vehicle({ ...item.vehicle, vin: item.vin }) : null))
    .filter(isNotNull) || [];

export const createCartFromActiveCartData = ({
  cart,
  accounts,
  orderItemIdsToRemove = [],
}: {
  cart: ActiveCartRawData;
  accounts: SupplierAccount[];
  orderItemIdsToRemove?: string[];
}) => {
  const vehicles = createVehicles(cart?.vehicles);

  const orders =
    cart.orders
      ?.map((order) => createCartOrderFromActiveCart({ order, vehicles, accounts, orderItemIdsToRemove }))
      .filter((order) => order.items.length > 0) || [];

  const laborApplication = createLaborAplications(cart);
  const laborHours = laborApplication.reduce((acc, application) => acc + application.duration, 0);

  return new Cart({
    id: cart.id,
    orders,
    vehicles,
    status: CartImprovedStatus.Created,
    isActive: true,
    totals: createCartTotalsFromCartOrders(orders, cart.laborRate || 0, laborHours),
    laborRate: cart.laborRate,
    laborApplication: createLaborAplications(cart),
  });
};

export const createCartFromSavedCartData = (cart: SavedCartRawData) => {
  const vehicles = createVehicles(cart?.vehicles);

  const activeOrders = cart.orders?.map((order) => createCartOrderFromActiveOrder(order, vehicles));

  const paidOrders = cart.paidOrders?.map((order) => createCartOrderFromPaidOrder(order, vehicles)) || [];

  return new Cart({
    id: cart.id,
    orders: activeOrders?.length ? activeOrders : paidOrders,
    vehicles,
    updatedAt: cart.updatedAt,
    status: cart.improvedStatus,
    isActive: cart.active,
    customerEmail: cart.customerEmail,
    notes: cart.notes,
    repairOrderNumber: cart.repairOrderNumber,
    showOnlyRetailPrice: cart.showRetailPricing,
    totals: createCartTotalsFromCartOrders(activeOrders || []),
    user: cart.user,
  });
};
