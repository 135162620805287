import { OnboardStepType } from 'shared/api';
import { useOnboardSteps } from 'store/queries/currentUser/useGetOnBoardSteps';
import { SignUpPages } from 'utils/url';
import { useCreateShopContext } from '../../CreateShopContext/CreateShopContext';

export const useHeader = () => {
  const { isStepCompleted } = useOnboardSteps();

  const { activeStep, previousStep, navigateToPreviousStep } = useCreateShopContext();

  const previousStepName = previousStep?.name.toLowerCase() ?? '';

  const showBackButton =
    previousStep?.id === SignUpPages.ShopType ? !isStepCompleted(OnboardStepType.ShopType) : Boolean(previousStepName);

  return {
    previousStepName,
    navigateToPreviousStep,
    showBackButton,
    showCreateShopTimeEstimate: activeStep.id === SignUpPages.ShopType,
  };
};
