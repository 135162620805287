import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useWelcomeModal } from 'features/onboarding';
import { useOpenAddSupplierModal } from 'hooks/supplierAccounts';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { getDashboardUrl } from 'utils/url';

export const usePhoneNumber = () => {
  const navigate = useAppNavigate();

  const {
    flags: { newWelcomeModal },
  } = useLaunchDarkly();

  const { open: openAddSupplierModal } = useOpenAddSupplierModal();

  const handleOpenAddSupplierModal = useCallback(() => {
    openAddSupplierModal('all');
  }, [openAddSupplierModal]);

  const { open: openWelcomeModal } = useWelcomeModal({ onAddSuppliers: handleOpenAddSupplierModal });

  const { shop } = useGetCurrentUser();

  const navigateToDashboard = useCallback(() => {
    if (newWelcomeModal) {
      openWelcomeModal();
    }

    navigate(getDashboardUrl());
  }, [navigate, newWelcomeModal, openWelcomeModal]);

  return {
    shopPhoneNumber: shop?.phone ?? '',
    navigateToDashboard,
  };
};
