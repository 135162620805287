import { Menu, type IconName } from '@partstech/ui';
import { useCallback } from 'react';
import { createSubCategoryFromQuery } from 'factories';
import { SubCategoryOption } from './Option/SubCategoryOption';
import type { PartSubCategory } from 'models';

type Props = {
  checkedIcon?: (option: PartSubCategory) => IconName;
  disabledPartTypeIds: string[];
  highlightedText?: string;
  onChange: (option: PartSubCategory[]) => void;
  onOpen: (option: PartSubCategory) => void;
  options: PartSubCategory[];
  renderSecondaryText?: (option: PartSubCategory) => string;
  value: PartSubCategory[];
  disabledText?: string;
  renderTag?: (option: PartSubCategory) => string;
};

const togglePartSubCategoryOption = (
  isSelected: (option: PartSubCategory) => boolean,
  option: PartSubCategory,
  value: PartSubCategory[]
): PartSubCategory[] =>
  isSelected(option)
    ? value
        .map((selectedOption) =>
          selectedOption.id === option.id
            ? createSubCategoryFromQuery(
                { id: selectedOption.id, name: selectedOption.name },
                selectedOption.categoryId,
                selectedOption.partTypes?.filter(
                  (selectedPartType) => !option.partTypes.find((partType) => partType.id === selectedPartType.id)
                )
              )
            : selectedOption
        )
        .filter((subCategory) => subCategory.partTypes.length > 0)
    : [...value, option];

export const SubCategories = ({
  checkedIcon,
  disabledPartTypeIds,
  highlightedText,
  onChange,
  onOpen,
  options,
  renderSecondaryText = () => '',
  value,
  disabledText,
  renderTag = () => '',
}: Props) => {
  const isSelected = useCallback(
    (option: PartSubCategory) =>
      value.some((selectedOption) => option.getMatchingPartTypes(selectedOption.partTypes).length > 0),
    [value]
  );

  const selectOption = useCallback(
    (option: PartSubCategory) => {
      if (!onChange) {
        return;
      }

      onChange(togglePartSubCategoryOption(isSelected, option, value));
    },
    [isSelected, onChange, value]
  );

  return (
    <Menu builtIn>
      {options.map((option, index) => (
        <SubCategoryOption
          option={option}
          disabledPartTypeIds={disabledPartTypeIds}
          highlightedText={highlightedText}
          key={`${option.id}-${index}`}
          isSelected={isSelected}
          onSelect={selectOption}
          onOpen={onOpen}
          renderSecondaryText={renderSecondaryText}
          checkedIcon={checkedIcon}
          disabledText={disabledText}
          renderTag={renderTag}
        />
      ))}
    </Menu>
  );
};
