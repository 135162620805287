import { usePrevious } from '@partstech/ui/hooks';
import { NavigationType, useMatch, useNavigationType } from 'react-router-dom';
import { Routes } from 'constant';
import { useGetSmsRegistrationData } from 'features/smsConnections';
import { useSmsConnectionsRouter } from 'hooks/smsConnections';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { SmsConnectionsPages } from 'utils/url';

export const useSmsConnectionsRedirect = () => {
  const navigationType = useNavigationType();

  const {
    pathParams: { hash },
    generateSmsConnectionsUrl,
  } = useSmsConnectionsRouter();

  const isSmsConnectionsPages = Boolean(useMatch(Routes.SMS_CONNECTIONS));
  const isSignUpPage = Boolean(useMatch(generateSmsConnectionsUrl(SmsConnectionsPages.SignUp)));
  const isLogInPage = Boolean(useMatch(generateSmsConnectionsUrl(SmsConnectionsPages.LogIn)));
  const isConnectPage = Boolean(useMatch(generateSmsConnectionsUrl(SmsConnectionsPages.Connect)));
  const isErrorPage = Boolean(useMatch(generateSmsConnectionsUrl(SmsConnectionsPages.Error)));

  const { smsRegistrationData, isSuccess, isError } = useGetSmsRegistrationData(
    { hash },
    { skip: !hash || !isSmsConnectionsPages }
  );

  const isNeedFirstRedirect = usePrevious(!isSuccess);

  const isAlreadyRegistered = smsRegistrationData?.alreadyRegistered;

  const { activeMember, isSuccess: isSuccessCurrentUser } = useGetCurrentUser();

  const authPage = isAlreadyRegistered ? SmsConnectionsPages.LogIn : SmsConnectionsPages.SignUp;

  if (!hash || isError || !isSmsConnectionsPages) {
    if (isErrorPage) {
      return null;
    }

    return generateSmsConnectionsUrl(SmsConnectionsPages.Error);
  }

  if (!isSuccess || !isSuccessCurrentUser) {
    return null;
  }

  if (!activeMember && isConnectPage) {
    return generateSmsConnectionsUrl(authPage);
  }

  if (activeMember && (isSignUpPage || isLogInPage)) {
    return generateSmsConnectionsUrl();
  }

  if (isNeedFirstRedirect && navigationType === NavigationType.Pop) {
    if (activeMember) {
      return generateSmsConnectionsUrl();
    }

    return generateSmsConnectionsUrl(authPage);
  }

  return null;
};
