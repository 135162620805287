import { useMatch } from 'react-router-dom';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { PagePermission, Routes } from 'constant';
import { useIsInvitationPage } from 'hooks/invitations';
import { useLastUrl } from 'hooks/localStorage';
import { useGetMyShopRedirect } from 'hooks/myShop';
import { useSignupQueryParams } from 'hooks/signup';
import { useIsSmsConnectionsPage } from 'hooks/smsConnections';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useOnboardSteps } from 'store/queries/currentUser/useGetOnBoardSteps';
import { getDashboardUrl, getLoginUrl, getSignUpUrl } from 'utils/url';
import { useSignUpQuestionsRedirect } from './useSignUpQuestionsRedirect';
import { useSmsConnectionsRedirect } from './useSmsConnectionsRedirect';

export const useGetRedirect = (permission: PagePermission) => {
  const [lastUrl] = useLastUrl();

  const isGoogleSignUpPage = useMatch(Routes.SIGN_UP_GOOGLE);
  const isInvitationPage = useIsInvitationPage();
  const isSmsConnectionsPage = useIsSmsConnectionsPage();

  const myShopRedirectUrl = useGetMyShopRedirect();

  const smsConnectionsRedirect = useSmsConnectionsRedirect();
  const signUpQuestionsRedirect = useSignUpQuestionsRedirect();

  const { hasCurrentUser, isSuccess, isFetching } = useGetCurrentUser();
  const { isOnboardCompleted } = useOnboardSteps();

  const { pathname, search } = useAppLocation();
  const query = useSignupQueryParams();

  const isUnauthorizedPage = permission === PagePermission.UNAUTHORIZED;
  const isAuthorizedNoShopPage = permission === PagePermission.AUTHORIZED_NO_SHOP;

  const shouldSkipRedirect = !isSuccess || isFetching || isInvitationPage;

  if (shouldSkipRedirect) {
    return null;
  }

  if (isSmsConnectionsPage) {
    if (smsConnectionsRedirect && smsConnectionsRedirect !== pathname) {
      return smsConnectionsRedirect;
    }

    return null;
  }

  if (!query?.code && isGoogleSignUpPage) {
    return getSignUpUrl();
  }

  if (!hasCurrentUser && !isUnauthorizedPage) {
    return getLoginUrl();
  }

  if (signUpQuestionsRedirect) {
    return signUpQuestionsRedirect;
  }

  if (hasCurrentUser && isUnauthorizedPage) {
    if (lastUrl && lastUrl !== `${pathname}${search}`) {
      return lastUrl;
    }

    return getDashboardUrl();
  }

  if (isAuthorizedNoShopPage) {
    if (signUpQuestionsRedirect) {
      return signUpQuestionsRedirect;
    }

    if (isOnboardCompleted) {
      return getDashboardUrl();
    }
  }

  if (myShopRedirectUrl) {
    return myShopRedirectUrl;
  }

  return null;
};
