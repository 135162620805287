import { useOnboardSteps } from 'store/queries/currentUser/useGetOnBoardSteps';
import { useSendShopData } from './useSendShopData';
import { useSendSubscriptionPackage } from './useSendSubscriptionPackage';
import { useSendSupplierAccountStats } from './useSendSupplierAccountStats';

type Args = {
  isIdentified: boolean;
};

export const useFullstory = ({ isIdentified }: Args) => {
  const { isOnboardCompleted } = useOnboardSteps();

  const skip = !isIdentified || !isOnboardCompleted;

  useSendShopData({ skip });
  useSendSubscriptionPackage({ skip });
  useSendSupplierAccountStats({ skip });
};
