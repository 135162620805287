import { css } from '@emotion/react';
import { Box, Button, ButtonGroup, Card, Divider, Icon, Scrollbar, Typography } from '@partstech/ui';
import confetti from 'images/content/signUp/confetti.png';
import { useWelcomeModalView } from './useWelcomeModalView';
import type { ModalProps, Theme } from '@partstech/ui';

const videoUrl = 'https://media.sequel.io/2f910ed5-9f94-4f85-9f54-6efee9678c72';

const styles = {
  video: (theme: Theme) => css`
    border: 0;
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: ${theme.sizing(2)};
  `,
  scrollbar: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.sizing(4)};
    padding: ${theme.sizing(6, 4, 8)};

    ${theme.breakpoints.down('md')} {
      padding: ${theme.sizing(4)};
    }
  `,
  header: (theme: Theme) => css`
    border-radius: inherit;
    height: ${theme.sizing(36)};
    background-image: url(${confetti});
    background-repeat: no-repeat;
  `,
};

type Props = {
  onAddSuppliers: () => void;
} & ModalProps;

export const WelcomeModalView = ({ onAddSuppliers, onClose }: Props) => {
  const { addSuppliers, cardRef, close, headerRef } = useWelcomeModalView({ onAddSuppliers, onClose });

  return (
    <Card
      ref={cardRef}
      display="flex"
      flexDirection="column"
      height={{ sm: '100dvh', md: 'auto' }}
      width="100%"
      maxWidth={159}
    >
      <Box ref={headerRef} display="flex" justifyContent="center" alignItems="center" css={styles.header}>
        <Card
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={14}
          width={65}
          borderWidth={0}
          background="white"
        >
          <Typography variant="h2">Welcome to PartsTech!</Typography>
        </Card>
      </Box>

      <Divider />

      <Scrollbar css={styles.scrollbar}>
        <Box mt={2} width="100%" maxWidth={142} textAlign="center">
          <Typography variant="h3">PartsTech in 90 Seconds</Typography>
        </Box>

        <Box width="100%" maxWidth={142}>
          <iframe
            src={videoUrl}
            title="Sequel video player"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            css={styles.video}
          />
        </Box>

        <Card
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          maxWidth={142}
          pt={4}
          px={4}
          pb={6}
          mb={2}
        >
          <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
            <Icon name="domain" size="default" color="subtleText" />
          </Box>

          <Typography variant="h3">Add unlimited suppliers</Typography>

          <Box mt={2} mb={9}>
            <Typography color="subtleText">
              Select which wholesale suppliers you want to search for parts and tires from. <br /> You’ll need your
              business’s account credentials in order to connect.
            </Typography>
          </Box>

          <ButtonGroup gap={4}>
            <Button size="dense" onClick={addSuppliers}>
              Add suppliers
            </Button>

            <Button size="dense" variant="text" onClick={close}>
              Skip to Partstech
            </Button>
          </ButtonGroup>
        </Card>
      </Scrollbar>
    </Card>
  );
};
